const target=document.querySelector('#ConsentTick')
/**
 *
 * @param {MouseEvent} event
 */
function flipConsent() {
  // const{target}=event
  const input =  /** @type {HTMLInputElement} */ (target)
  if(input.dataset['off']) {
    delete input.dataset['off']
  } else {
    input.dataset['off']=true
  }
  if(input.dataset['off']) {
    input.value=''
  } else {
    input.value='✅'
  }
}

window['flipConsent'] = flipConsent